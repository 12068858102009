<!-- 公司新闻 -->
<template>
  <div>
    <div v-show="show" class="notice-body">
      <ul class="notice-list">
        <li>
          <a @click="isshow" class="title" title="泛亚“红领巾在行动”团委活动">
            泛亚“红领巾在行动”团委活动
          </a>
          <a class="desc">
            为进一步加强成员之间的交流与合作，激发和调整泛亚团员心里素质、身体素质，以及品德素质，充分发挥泛亚团员的各种优势和潜能，使泛亚团员在自信心、责任感、适应性、创新能力、全体合作精神等方面得到进一步的增强...
          </a>
          <span class="news-list-item-date">2022-05-05</span>
        </li>
      </ul>
      <!-- <div class="news-pagination">
          <table cellspacing="0" cellpadding="0">
            <td class="right" align="right">
              <div class="pagebreak">
                <table cellspacing="0" cellpadding="0">
                  <tbody>
                    <td class="page_text page_home">
                      <a class="page_text page_home" href="">首页</a>
                    </td>
                    <td class="page_text page_prev"></td>
                    <td class="page_no"><a class="page_current">1</a></td>
                    <td class="page_text page_next"></td>
                    <td class="page_text page_end">
                      <a class="page_text page_end" href="">尾页</a>
                    </td>
                    <td class="page_jump"></td>
                  </tbody>
                </table>
              </div>
            </td>
          </table>
        </div> -->
    </div>

    <div v-show="!show" class="notice-body">
      <a @click="isshow" class="notice-back">&lt;&nbsp;返回新闻公共列表</a>
      <div class="notice-show">
        <div class="title">
          <h3>泛亚“红领巾在行动”团委活动</h3>
          <span>发布时间：2022-05-05 13:17:47</span>
        </div>
        <div class="info">
          <p>
            <span style="font-size: 14px; font-family: 微软雅黑"
              >为进一步加强成员之间的交流与合作，激发和调整泛亚团员心里素质、身体素质，以及品德素质，充分发挥泛亚团员的各种优势和潜能，使泛亚团员在自信心、责任感、适应性、创新能力、全体合作精神等方面得到进一步的增强和提升，特制定本次“红领巾在行动”为主题的拓展活动。 </span
            ><br />
            <br />
          </p>
          <div>
            <img
              src="@/assets/Uploads/companyimg/005.png"
              alt=""
              width="600"
              height="500"
            /><br />
          </div>
          <p>
            <br />
            <span style="font-size: 14px; font-family: 微软雅黑"
              >&nbsp;<span
                style="
                  background-color: #ffffff;
                  color: #565656;
                  font-size: 14px;
                  font-family: 微软雅黑;
                "
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
              >环保从自身做起，我们的第一项活动就是徒步5公里。浩浩荡荡的队伍出发，小伙伴都神采奕奕，时不时还回头关注下其他队伍的同学。工作人员也举着团旗，一路奔向目标。小伙伴们还进行了分队：</span
            ><strong
              ><span style="font-size: 14px; font-family: 微软雅黑"
                >梦之队、猛虎队、冲锋队、阿尔法队、雄鹰队、亮剑队、青春队</span
              ></strong
            ><span style="font-size: 14px; font-family: 微软雅黑"
              >，还有团委工作人员组成的</span
            ><span style="font-size: 14px; font-family: 微软雅黑"
              ><strong>天使队</strong></span
            ><span style="font-size: 14px; font-family: 微软雅黑"
              >。看我们的工作人员向所有参加活动的团员致敬。 </span
            ><br />
          </p>
          <div>
            <img
              src="@/assets/Uploads/companyimg/005-1.jpg"
              alt=""
              width="600"
              height="500"
            /><br />
          </div>
          <p>
            <br />
            <br />
            <span style="font-size: 14px; font-family: 微软雅黑"
              ><span
                style="
                  background-color: #ffffff;
                  color: #565656;
                  font-size: 14px;
                  font-family: 微软雅黑;
                "
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
              >团队合作往往能激发出团体不可思议的潜力，集体协作干出的成果往往能超过成员个人业绩的总和。正所谓
              “同心山成玉，协力土变金”。</span
            ><br />
            <br />
          </p>
          <div>
            <img
              src="@/assets/Uploads/companyimg/005-2.jpg"
              alt=""
              width="600"
              height="600"
            /><br />
          </div>
          <p>
            <span style="font-size: 14px; font-family: 微软雅黑"></span
            ><span style="font-size: 14px; font-family: 微软雅黑"
              ><span
                style="
                  background-color: #ffffff;
                  color: #565656;
                  font-size: 14px;
                  font-family: 微软雅黑;
                "
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
              >我们的“亮剑队”取得了徒步和拔河的优胜，奖品为迷你电扇、运动手环。“珠行万里”是“青春队”取得优胜，奖品为卡通颈枕和腰枕。“不倒森林”和第四个项目“摸石过河”，取得优胜，奖品为蓝牙耳机和高级签字笔。猛虎不愧是丛林之王，获胜的两个项目都是和山林有关。另外，我们还设置了若干个鼓励奖，奖励捡空瓶的环保达人，把我们今日活动中所产生的垃圾都带走，不给大自然造成污染。
              &nbsp;</span
            ><br />
            <br />
            <br />
          </p>
          <div>
            <span style="font-size: 14px; font-family: 微软雅黑"></span
            ><img
              src="@/assets/Uploads/companyimg/005-3.jpg"
              alt=""
              width="600"
              height="500"
            /><br />
          </div>
          <p>
            <span style="font-size: 14px; font-family: 微软雅黑"></span
            ><span style="font-size: 14px; font-family: 微软雅黑"
              ><span
                style="
                  background-color: #ffffff;
                  color: #565656;
                  font-size: 14px;
                  font-family: 微软雅黑;
                "
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
              >本次活动的团队分享点：&nbsp;细节、心态、耐性、组织规划、毅力、目的性、放手一搏、&nbsp;压力：你给球压力，球就会跑，你给别人压力别人也会给你压力。&nbsp;稳住就是速度，欲速则不达，心态决定结果；&nbsp;培养规则和纪律意识，规则不是惩罚而是保护。团队是一个整体，每个人都像是这个齿轮上的一个齿，少了谁都不能正常运?在这个项目中，你的责任在什么地方?是不是仅仅抓住前面的竿子就够了?在这个项目中，你是否感受到了团队的默契和节奏?这种感觉是如何形成的?看似不可能完成的任务，通过团队的力量达到之后，你的内心感受是怎样的?&nbsp;
            </span>
          </p>
          <p><br /></p>
        </div>
        <br />
        <!-- <div class="media-link">
        <div class="link-box">
          本文链接：<span class="link-url-span" id="linkUrl">http://i360.cn/news/content/2.html</span>
          <span class="copy-url-btn" id="copyUrlBtn" data-clipboard-target="linkUrl"
            data-clipboard-text="">
            复制本文链接
          </span>
          <i class="l-tips">链接已复制</i>
        </div>
      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
    };
  },
  mounted() {
    if (this.$route.params.type) {
      this.show = false;
    }
  },
  methods: {
    isshow() {
      this.show = !this.show;
    },
  },
};
</script>

<style scoped></style>
